<template>
  <div class="position-relative">
    <b-row>
      <b-col md="6">
        <!-- <span v-if="checkPermission('all_access')"> -->

        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" v-b-toggle.sidebar-AddNewPermission>Add New
          permission</b-button>
        <!-- </span> -->
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="8">
            <b-form-group   label-cols-sm="2" label-align-sm="right" label-size="md" label-for="filterInput">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group   label-cols-sm="4" label-align-sm="right" label-size="sm"
              label-for="perPageInput">
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                @change="savePerPage(perPage)" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-table
  v-if="permissions.length > 0"
  :items="permissions"
  :fields="fields"
  responsive="sm"
  :sticky-header="false"
  striped
  small
  id="permission-table"
  :per-page="perPage"
  :current-page="currentPage"
  :filter="filter"
  :filter-included-fields="filterOn"
  @filtered="onFiltered"
>
      <template #cell(permissions)="row">
        <span>{{ row.item.name }}</span>
      </template>


      <template #cell(roles)="row">

        <p v-for="role in row.item.roles" :key="role.id">{{ role.name }}</p>
        <!-- <span>{{ row.item.permissions[0].display_name }}</span> -->
      </template>

      <!-- <template #cell(status)="row">
          <b-badge pill :variant="`light-${resolveUserStatusVariant(row.item.active)}`" class="text-capitalize">
            {{ resolveUserStatus(row.item.active) }}
          </b-badge>
        </template> -->

      <template #cell(action)="row">
        <!-- <span v-if="checkPermission('all_access')"> -->

        <!-- <span v-if="row.item.active">
              <feather-icon icon="SlashIcon" size="16" class="text-body align-middle mr-25 cursor-pointer"
                v-b-tooltip.hover.v-danger title="Block User" @click="block_unblockUser(row.item.id, false)" />
            </span>
  
            <span v-if="!row.item.active">
              <feather-icon icon="UnlockIcon" size="16" class="text-body align-middle mr-25 cursor-pointer"
                v-b-tooltip.hover.v-warning title="Unblock User" @click="block_unblockUser(row.item.id, true)" />
            </span> -->

        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
          </template>
          <b-dropdown-item v-b-toggle.sidebar-editpermission @click="sendPermissionEditProps(row.item)">
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span>Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deletePermission(row.item.id)">
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
        <!-- </span> -->
      </template>
    </b-table>
    <div v-else class="text-center mt-4">{{ emptyPermissionMessage }}</div>
    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" first-number last-number
      class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>

    <add-new-permission @refresh="getAllPermissions" />
    <edit-permission :editpermission="editPermission" @refresh="getAllPermissions" />

    <b-overlay :show="loader" no-wrap spinner-variant="primary"></b-overlay>
  </div>
</template>
  
<script>
import {
  BTable,
  BButton,
  VBToggle,
  BOverlay,
  BRow,
  BCol,
  BPagination,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BBadge,
  VBTooltip,
  FormSelectPlugin
} from "bootstrap-vue";
import axios from "axios";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNewPermission from "./AddNewPermission.vue";
import EditPermission from "./EditPermission.vue";
import User from '../../../Helpers/User'


export default {
  components: {
    BTable,
    BButton,
    BRow,
    BCol,
    BPagination,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    ToastificationContent,
    AddNewPermission,
    EditPermission
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: "25",
      pageOptions: [25, 50, 100, 200],
      emptyPermissionMessage: "No Data Found.",
      currentPage: 1,
      totalRows: 1,
      filter: null,
      filterOn: [],
      fields: ["display_name", "name", "roles", { key: "action", label: "Action", tdClass: 'actionClass' }],
      permissions: [],
      loader: false,
      permissionData: {},
      editPermission: {}
    };
  },
  mounted() {
    //   this.perPage = this.getPerPage() ? this.getPerPage() : "25";
  },
  created() {
    this.getAllPermissions();
  },

  methods: {
    checkPermission(permission) {

      return User.checkPermission(permission)
    },

    sendPermissionEditProps(param) {
      console.log('param', param)
      this.editPermission = param
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllPermissions() {
      this.loader = true;
      axios
        .get(process.env.VUE_APP_API + "/permissions")
        .then((res) => {
          this.loader = false;
          this.permissions = res["data"];
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
        });
    },

    deletePermission(permissionId) {
      if (permissionId) {
        this.loader = true
        axios({
          method: 'post',
          url: process.env.VUE_APP_API + '/delete-permission',
          data: {
            'permissionId': permissionId,
          }
        })
          .then((res) => {
            if (res.data.success) {
              this.getAllPermissions()
              this.loader = false
              this.$toast
                ({
                  component: ToastificationContent,
                  props: {
                    title: 'Permission Deleted',
                    icon: 'AlertOctagonIcon',
                    text: '',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-right'
                  })
            }
            else {
              this.loader = false
              this.$toast
                ({
                  component: ToastificationContent,
                  props: {
                    title: 'Failed',
                    icon: 'AlertOctagonIcon',
                    text: '',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-left'
                  })
            }
          })
          .catch((error) => {
            this.loader = false
            console.log(error);
          });

      }
    },
  },



}

</script>
  
<style>
.actionClass {
  max-width: 300px;
}
</style>